/* You can add global styles to this file, and also import other style files */
body {
  font-family: helvetica;
}
textarea:focus, input:focus, select:focus, option:focus{
    outline: none;
}
textarea, input, select, option{
    color: #4F5151;
}
option:focus{
    background-color:#FFF;
    outline:none;
    border:none;
    box-shadow:none;
}
a {
  color: #119fca;
}
button {
  background-color: white;
  color: #4A488E;
  padding: 0;
  border: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 10px;

}
button:active {
  transform: scale3d(0.9, 0.9, 1);
  -moz-transform: scale3d(0.9, 0.9, 1);
  -webkit-transform: scale3d(0.9, 0.9, 1);
}
button:focus {
  outline: 0;
}
.category_button {
  width: auto;
  height: 100%;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 20px;
  background-color: white;
  color: #119fca;
  border-radius: 0;
}
.category_button:hover {
  background-color: #119fca;
  color: white;
}
.product {
  width: calc(20% - 20px);
  margin: 10px;
  margin-bottom: 30px;
  padding: 20px;
  background-color: #FFFFFF;
  border-radius: 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.product > span:nth-child(1) {
  font-weight: bold;
}
@media only screen and (max-width: 1000px) {
  .product {
    width: calc(40% - 20px);
  }
}
@media only screen and (max-width: 500px) {
  .product {
    width: calc(80% - 20px);
  }
}
